import React from "react";

const ShippingAndReturn = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8 px-4">
      {/* Main Content */}
      <div className="w-full max-w-3xl bg-white p-6 rounded shadow-md">
        <h1 className="text-4xl font-bold text-center mb-6">
          Shipping & Return Policy
        </h1>

        {/* Shipping Information Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Shipping Policy</h2>
          <p className="text-slate-600 leading-relaxed">
            At ST-Enterprises, we aim to deliver your products in a fast and
            reliable manner. We charge a shipping fee of Rs299. This will vary
            from product to product and will be calculated accurately at the time of order.
          </p>

          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-4">
            <li>
              Delivery Time: Orders are typically processed and shipped within
              1-3 business days.
            </li>
            <li>
              Shipping Locations: We currently ship across Lahore, and offer
              standard shipping to most locations.
            </li>
            <li>
              Delivery Partners: We partner with leading logistics providers to
              ensure timely delivery.
            </li>
          </ul>

          <p className="text-slate-600 leading-relaxed mt-4">
            Once your order is shipped, you will receive a confirmation call so
            you can follow the delivery progress.
          </p>
        </section>

        {/* Return Policy Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Return Policy</h2>
          <p className="text-slate-600 leading-relaxed">
            We want you to be satisfied with your purchase. However, we
            understand that sometimes things don’t go as planned. Our return
            policy varies depending on the product, so please make sure to check
            the product details for specific return information.
          </p>

          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-4">
            <li>
              Return Period: Most products can be returned within 7 days from
              the delivery date. Specific return windows will be mentioned on
              the product page.
            </li>
            <li>
              Product Condition: Products must be returned in their original
              packaging, unused, and with all tags intact.
            </li>
            <li>
              Refunds: Once your return is received and inspected, we will
              notify you of the status of your refund. If approved, a refund
              will be processed to your original method of payment.
            </li>
          </ul>

          <p className="text-slate-600 leading-relaxed mt-4">
            To initiate a return, please contact our support team at{" "}
            <span className="font-semibold">+92 301 6760424</span> with your
            order details.
          </p>
        </section>

        {/* Contact Us Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
          <p className="text-slate-600 leading-relaxed">
            If you have any questions about our Shipping & Return Policy, feel
            free to reach out to our customer service team at{" "}
            <span className="font-semibold">stenterprises424@gmail.com</span> or
            call us at <span className="font-semibold">+92 301 6760424</span>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default ShippingAndReturn;
