import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8 px-4">
      {/* Main Content */}
      <div className="w-full max-w-3xl bg-white p-6 rounded shadow-md">
        <h1 className="text-4xl font-bold text-center mb-6">Privacy Policy</h1>

        {/* Introduction Section */}
        <section className="mb-6">
          <p className="text-slate-600 leading-relaxed">
            At ST-Enterprises, we value your privacy and are committed to
            protecting your personal information. This Privacy Policy explains
            how we collect, use, disclose, and safeguard your data when you use
            our website and services. Please read this policy carefully to
            understand our practices.
          </p>
        </section>

        {/* Information Collection Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            Information We Collect
          </h2>
          <p className="text-slate-600 leading-relaxed">
            We may collect the following types of information when you interact
            with our website:
          </p>
          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-2">
            <li>
              Personal identification information (name, email address, phone
              number, etc.)
            </li>
            <li>Payment information when you make a purchase</li>
            <li>
              Technical data such as your IP address, browser type, and
              operating system
            </li>
            <li>Usage data, including browsing actions and patterns</li>
          </ul>
        </section>

        {/* Use of Information Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            How We Use Your Information
          </h2>
          <p className="text-slate-600 leading-relaxed">
            We use the information we collect in the following ways:
          </p>
          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-2">
            <li>To provide, operate, and maintain our services</li>
            <li>To process your transactions and send order updates</li>
            <li>To communicate with you, including customer support</li>
            <li>To personalize your experience on our site</li>
            <li>
              To analyze how users interact with our website for improvements
            </li>
          </ul>
        </section>

        {/* Sharing Information Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            How We Share Your Information
          </h2>
          <p className="text-slate-600 leading-relaxed">
            We may share your information with third-party service providers to
            help us operate our business and provide services to you. These
            include:
          </p>
          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-2">
            <li>Payment processors</li>
            <li>Shipping providers</li>
            <li>Marketing platforms for newsletters and promotions</li>
          </ul>
          <p className="text-slate-600 leading-relaxed mt-2">
            We do not sell your personal information to third parties.
          </p>
        </section>

        {/* Data Security Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
          <p className="text-slate-600 leading-relaxed">
            We implement appropriate security measures to protect your personal
            information from unauthorized access, disclosure, alteration, or
            destruction. However, no method of transmission over the internet or
            method of electronic storage is completely secure, and we cannot
            guarantee absolute security.
          </p>
        </section>

        {/* Your Rights Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p className="text-slate-600 leading-relaxed">
            You have the right to:
          </p>
          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-2">
            <li>Access the personal information we hold about you</li>
            <li>Request correction or deletion of your personal information</li>
            <li>Opt-out of receiving marketing communications from us</li>
            <li>Withdraw consent for data processing at any time</li>
          </ul>
        </section>

        {/* Changes to Privacy Policy Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            Changes to This Policy
          </h2>
          <p className="text-slate-600 leading-relaxed">
            We may update this Privacy Policy from time to time. We encourage
            you to review this policy periodically to stay informed of any
            changes. Your continued use of our website after changes are posted
            constitutes your acceptance of the updated Privacy Policy.
          </p>
        </section>

        {/* Contact Us Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="text-slate-600 leading-relaxed">
            If you have any questions about this Privacy Policy or our
            practices, please contact us at:
          </p>
          <p className="text-slate-600 leading-relaxed">
            Email: stenterprises424@gmail.com <br />
            Phone: +92 301 6760424 <br />
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
