import React from "react";

const AboutUs = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">
      <div className="w-full max-w-3xl bg-white p-6 rounded shadow-md">
        <h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
        <p className="text-lg text-gray-700 leading-relaxed mb-4">
          Welcome to <strong>ST-Enterprises</strong>, your trusted destination
          for quality products and exceptional service. Founded in 2024, our
          mission is simple: to provide top-notch products at unbeatable prices.
          We believe in innovation, convenience, and customer satisfaction.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed mb-4">
          At ST-Enterprises, we’re driven by passion and commitment to
          excellence. Our journey began with a vision to bring the latest and
          most reliable gadgets and accessories to your doorstep. Over time,
          we’ve grown into a trusted name in the industry, with a dedicated team
          that always strives for the best.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed">
          Thank you for choosing us – we’re excited to serve you!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
