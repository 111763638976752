import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer
      style={{
        background: "#000000", // Black background
      }}
    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* About Section */}
        <div>
          <Link to="/about-us">
            <h3 className="text-lg font-bold mb-2" style={{ color: "white" }}>
              About Us
            </h3>
          </Link>
          <p style={{ color: "#C0C0C0" }}>
            ST-Enterprises is your go-to destination for the latest gadgets,
            accessories, and electronics. We bring innovation and convenience to
            your doorstep.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h3 className="text-lg font-bold mb-2" style={{ color: "white" }}>
            Quick Links
          </h3>
          <ul className="space-y-2">
            <li>
              <Link
                to="/product-category" // Use Link component for internal navigation
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Shop
              </Link>
            </li>
            <li>
              <Link
                to="/about-us" // Update the About Us link
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Our Team
              </Link>
            </li>
          </ul>
        </div>

        {/* Customer Support */}
        <div>
          <h3 className="text-lg font-bold mb-2" style={{ color: "white" }}>
            Customer Support
          </h3>
          <ul className="space-y-2">
            <li>
              <Link
                to="/shipping-return"
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Shipping & Returns
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-of-service"
                className="hover:underline"
                style={{ color: "#C0C0C0" }}
              >
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div>
          <h3 className="text-lg font-bold mb-2" style={{ color: "white" }}>
            Follow Us
          </h3>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61563109363726&mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              style={{ color: "#C0C0C0" }}
            >
              Facebook
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              style={{ color: "#C0C0C0" }}
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/st.enterprises?igsh=MWRvbHFlMmo5OTNnYg%3D%3D&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
              style={{ color: "#C0C0C0" }}
            >
              Instagram
            </a>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-500 mt-6 pt-4 text-center">
        <p className="font-bold" style={{ color: "#C0C0C0" }}>
          &copy; {new Date().getFullYear()} ST-Enterprises. All rights reserved.
        </p>
        <p className="text-sm" style={{ color: "#C0C0C0" }}>
          Developed by AHMAD HASSAN
        </p>
      </div>
    </footer>
  );
};

export default Footer;
