import React, { useEffect, useState } from "react";
import SummaryApi from "../common"; // Assuming you have an API file
import { useParams } from "react-router-dom";
import moment from "moment"; // For date formatting

const ReviewsPage = () => {
  const { productId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchProductAndReviews = async () => {
      try {
        const [reviewsResponse, productResponse] = await Promise.all([
          fetch(SummaryApi.getReviews(productId).url),
          fetch(SummaryApi.productDetails.url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ productId }),
          }),
        ]);

        const reviewsData = await reviewsResponse.json();
        const productData = await productResponse.json();

        console.log("Reviews Response:", reviewsData); // Log the reviews response
        console.log("Product Response:", productData); // Log the product response

        // Check if reviews were fetched successfully
        if (reviewsData.success) {
          setReviews(reviewsData.reviews);
        } else {
          console.error("Failed to fetch reviews:", reviewsData.message);
        }

        // Check if product details were fetched successfully
        if (productData.success) {
          setProduct(productData.data);
        } else {
          console.error(
            "Failed to fetch product details:",
            productData.message
          );
        }
      } catch (error) {
        console.error("Error fetching product and reviews:", error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchProductAndReviews();
  }, [productId]);

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <p>Loading reviews...</p>
      ) : (
        <>
          {product && (
            <div className="product-details">
              <h2 className="text-2xl font-bold">{product.productName}</h2>
              <p className="text-gray-600">{product.description}</p>
              <p className="text-red-500">Price: {product.price}</p>
            </div>
          )}
          <h3 className="text-xl font-semibold mt-4">Reviews</h3>
          <div className="reviews-list">
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review._id} className="review-item">
                  <p>
                    <strong>Rating:</strong> {review.rating} / 5
                  </p>
                  <p>
                    <strong>Comment:</strong> {review.comment}
                  </p>
                  <p>
                    <strong>Name:</strong> {review.name} {/* Add name field */}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {moment(review.createdAt).format("LL")}
                  </p>
                </div>
              ))
            ) : (
              <p>No reviews yet for this product.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewsPage;
