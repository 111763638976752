import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App"; // Removed if not used
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { CartProvider } from "./context/CartContext"; // Ensure CartProvider is imported

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CartProvider>
      {" "}
      {/* Ensure CartProvider is wrapping the entire app */}
      <RouterProvider router={router} />
    </CartProvider>
  </Provider>
);

reportWebVitals();
