import React, { useContext, useEffect, useState } from "react";
import fetchCategoryWiseProduct from "../helpers/fetchCategoryWiseProduct";
import displayINRCurrency from "../helpers/displayCurrency";
import { Link } from "react-router-dom";
import addToCart from "../helpers/addToCart";
import Context from "../context";
import scrollTop from "../helpers/scrollTop";

const CategroyWiseProductDisplay = ({ category, heading }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadingList = new Array(13).fill(null);

  const { fetchUserAddToCart } = useContext(Context);

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id);
    fetchUserAddToCart();
  };

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(category);
    setLoading(false);
    setData(categoryProduct?.data);
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  return (
    <div className="container mx-auto px-4 my-6 relative">
      <h2 className="text-xl sm:text-2xl font-semibold py-4">{heading}</h2>

      {/* Responsive grid layout for small screens */}
      <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,minmax(180px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(300px,320px))] gap-4 md:gap-6">
        {loading
          ? loadingList.map((_, index) => (
              <div
                key={"loading" + index}
                className="w-full h-48 bg-gray-200 animate-pulse rounded-md"
              ></div>
            ))
          : data.map((product) => (
              <Link
                to={`/product/${product?._id}`}
                key={product?._id}
                className="bg-white rounded-md shadow-sm hover:shadow-lg transition-all"
                onClick={scrollTop}
              >
                {/* Product Image */}
                <div className="h-40 sm:h-48 md:h-56 bg-gray-100 flex items-center justify-center p-2 rounded-t-md">
                  <img
                    src={product.productImage[0]}
                    alt={product?.productName}
                    className="object-contain h-full w-full hover:scale-110 transition-all"
                  />
                </div>

                {/* Product Details */}
                <div className="p-4">
                  <h2 className="font-medium text-sm sm:text-base md:text-lg text-black">
                    {product?.productName}
                  </h2>
                  <p className="text-xs sm:text-sm capitalize text-gray-500">
                    {product?.category}
                  </p>

                  <div className="flex justify-between items-center mt-2">
                    <p className="text-red-600 font-semibold text-sm sm:text-base">
                      {displayINRCurrency(product?.sellingPrice)}
                    </p>
                    <p className="text-xs sm:text-sm text-gray-400 line-through">
                      {displayINRCurrency(product?.price)}
                    </p>
                  </div>

                  <button
                    className="w-full mt-2 text-xs sm:text-sm bg-red-600 text-white px-4 py-1 rounded-full hover:bg-red-700 transition-all"
                    onClick={(e) => handleAddToCart(e, product?._id)}
                  >
                    Add to Cart
                  </button>
                </div>
              </Link>
            ))}
      </div>
    </div>
  );
};

export default CategroyWiseProductDisplay;
