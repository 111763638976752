import React from "react";
import CategoryList from "../components/CategoryList";
import BannerProduct from "../components/BannerProduct";
import HorizontalCardProduct from "../components/HorizontalCardProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";

const Home = () => {
  return (
    <div>
      <BannerProduct />
      <CategoryList />
      <HorizontalCardProduct category={"Monitors"} heading={"Monitors"} />
      <VerticalCardProduct category={"Mouse"} heading={"Mouse"} />
      <VerticalCardProduct category={"Keyboard"} heading={"Keyboard"} />
      <VerticalCardProduct category={"Headphones"} heading={"Headphones"} />
      <HorizontalCardProduct category={"Airpodes"} heading={"Top's Airpodes"} />
      <VerticalCardProduct
        category={"Accessories"}
        heading={"Top Accessories"}
      />
      <VerticalCardProduct category={"Bags"} heading={"Bags"} />
      <VerticalCardProduct
        category={"Speakers"}
        heading={"Bluetooth Speakers"}
      />
      <VerticalCardProduct category={"Macbook"} heading={"Macbook"} />
      <VerticalCardProduct
        category={"Window Machines"}
        heading={"Window Machines"}
      />
    </div>
  );
};

export default Home;
