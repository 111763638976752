import React from "react";
import { useSelector } from "react-redux";
import logo from "../assest/banner/st.jpg"; // Correct path to the uploaded logo

const ContactUs = () => {
  const user = useSelector((state) => state?.user?.user); // To fetch the current user details from Redux

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
      {/* Logo Section */}
      <aside className="bg-white w-full max-w-3xl customShadow p-6 rounded">
        <div className="flex justify-center items-center flex-col">
          <div className="cursor-pointer relative flex justify-center">
            <img
              src={logo}
              className="w-32 h-32 rounded-full" // Increased size for logo
              alt="Company Logo"
            />
          </div>
        </div>
      </aside>

      {/* Main Contact Us Content */}
      <main className="w-full max-w-3xl bg-white customShadow p-6 mt-6 rounded">
        <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>

        {/* Company Contact Information Section */}
        <section className="bg-white p-6 border border-slate-300 rounded mb-4">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Get in Touch
          </h2>
          <p className="text-slate-600 leading-relaxed text-center mb-6">
            Have any questions or concerns? We’re always here to help! Feel free
            to reach out to us through any of the methods below, and we’ll get
            back to you as soon as possible.
          </p>

          <div className="flex flex-col md:flex-row justify-between items-center md:items-start mb-4">
            <div className="w-full md:w-1/2 mb-4 md:mb-0 text-center">
              <h3 className="text-xl font-semibold">Email Us</h3>
              <p className="text-lg text-slate-600">
                stenterprises424@gmail.com
              </p>
            </div>

            <div className="w-full md:w-1/2 mb-4 md:mb-0 text-center">
              <h3 className="text-xl font-semibold">Call Us</h3>
              <p className="text-lg text-slate-600">+92 301 6760424</p>
              <p className="text-lg text-slate-600">+92 3048819893</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;
