import React, { useState, useEffect } from "react";
import "../pages/ForgetPassword.css"; // Include the styles in the same folder as your component
import almondImage from "../assest/banner/almond_image.jpg"; // Path to the downloaded image

const ForgotPassword = () => {
  const [text, setText] = useState("");
  const [showCoffee, setShowCoffee] = useState(false);
  const fullText = "Oops, forgot again?";

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setText((prev) => prev + fullText[index]);
      index++;
      if (index === fullText.length) {
        clearInterval(interval);
      }
    }, 100); // typing speed
    return () => clearInterval(interval);
  }, []);

  const handleHelpClick = () => {
    setShowCoffee(true);
    setTimeout(() => {
      setShowCoffee(false); // Hide the coffee after a short delay
    }, 4000); // Show for 4 seconds
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-box">
        <h1 className="typing-text">{text}</h1>

        <p className="fade-in">
          It's okay, we've all been there. Just click below, and we'll help you
          remember.
        </p>

        <button className="animated-button" onClick={handleHelpClick}>
          Help
        </button>

        {/* Coffee image with message */}
        {showCoffee && (
          <div className="coffee-container">
            <img src={almondImage} alt="Coffee" className="coffee-image" />
            <p className="coffee-message">This will help you remember!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
