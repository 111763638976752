import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa"; // Import the FaStar icon

const ReviewPage = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch reviews from the API
  const fetchReviews = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/reviews"); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setReviews(data); // Assuming your API returns an array of reviews
    } catch (error) {
      console.error("Failed to fetch reviews:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch reviews when the component mounts
  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Customer Reviews</h1>
      {loading ? (
        <p className="text-center">Loading reviews...</p>
      ) : reviews.length === 0 ? (
        <p className="text-center">No reviews available.</p>
      ) : (
        <div>
          {reviews.map((review) => (
            <div
              key={review.id}
              className="border rounded-lg shadow-md p-4 mb-6 bg-white"
            >
              <h3 className="text-xl font-semibold">{review.name}</h3>
              <div className="flex mb-2">
                {/* Render filled stars */}
                {[...Array(review.rating)].map((_, index) => (
                  <FaStar key={index} className="text-yellow-500" />
                ))}
                {/* Render empty stars */}
                {[...Array(5 - review.rating)].map((_, index) => (
                  <FaStar key={index} className="text-gray-300" />
                ))}
              </div>
              <p className="text-gray-700">{review.reviewText}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewPage;
