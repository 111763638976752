import SummaryApi from "../common";
import { toast } from "react-toastify";

const addToCart = async (e, id) => {
  try {
    // Stop the event from propagating and prevent default behavior
    e?.stopPropagation();
    e?.preventDefault();

    // Send the request to add the product to the cart
    const response = await fetch(SummaryApi.addToCartProduct.url, {
      method: SummaryApi.addToCartProduct.method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ productId: id }), // Send product ID in request body
    });

    // Check if the response was successful (status code 200-299)
    if (response.ok) {
      const responseData = await response.json();

      if (responseData.success) {
        toast.success(
          responseData.message || "Product added to cart successfully."
        );
        return responseData; // Return data if successful
      } else {
        toast.error(responseData.message || "Failed to add product to cart.");
        return responseData; // Return data with an error
      }
    } else {
      // Handle non-2xx HTTP responses
      const errorData = await response.json();
      toast.error(errorData.message || "Something went wrong.");
      return errorData; // Return error data
    }
  } catch (error) {
    // Catch and handle any network or unexpected errors
    console.error("Error adding product to cart:", error);
    toast.error("Failed to add product to cart. Please try again.");
    return { success: false, error: error.message };
  }
};

export default addToCart;
