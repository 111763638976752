import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CartContext from "../context/CartContext"; // Context for managing cart state
import { toast } from "react-toastify";

const DeliveryInformation = () => {
  const { cart } = useContext(CartContext); // Get the cart data from context
  const navigate = useNavigate();
  const location = useLocation();

  // State for the form data and form errors
  const [form, setForm] = useState({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const deliveryCharge = 299;
  const subtotal = location.state?.subtotal || 0;
  const totalAmount = subtotal + deliveryCharge;

  // Handle input changes in the form
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Validate form inputs
  const validateForm = () => {
    const errors = {};
    if (!form.name) errors.name = "Full Name is required";
    if (!form.phone) errors.phone = "Phone Number is required";
    if (!form.address) errors.address = "Address is required";
    if (!form.city) errors.city = "City is required";
    if (!form.state) errors.state = "State is required";
    if (!form.postalCode) errors.postalCode = "Postal Code is required";
    return errors;
  };

  // Handle form submission
  const handleOrder = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    // If no validation errors, proceed to create the order
    if (Object.keys(errors).length === 0) {
      const orderData = {
        customerName: form.name,
        customerPhone: form.phone,
        customerAddress: {
          addressLine: form.address,
          city: form.city,
          state: form.state,
          postalCode: form.postalCode,
        },
        products: cart.map((item) => ({
          productId: item.productId._id,
          productName: item.productId.productName,
          quantity: item.quantity,
          price: item.productId.sellingPrice,
        })),
        totalAmount,
        status: "Pending", // Default order status
      };

      try {
        // Send order data to backend API
        const response = await fetch("https://api.st-enterprises.store/api/orders", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData), // Convert order data to JSON
        });

        const responseData = await response.json();
        if (response.ok && responseData.success) {
          toast.success("Order placed successfully!");
          navigate("/"); // Redirect to homepage or order confirmation page
        } else {
          toast.error("Failed to place order.");
        }
      } catch (error) {
        console.error("Error placing order:", error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="container mx-auto my-5">
      <h2 className="text-2xl font-bold mb-5">Delivery Information</h2>
      <div className="flex flex-col lg:flex-row gap-10 lg:justify-between p-4">
        {/* Delivery Form */}
        <div className="w-full max-w-2xl bg-white p-4 border border-slate-300 rounded">
          <form onSubmit={handleOrder}>
            <div className="mb-4">
              <label htmlFor="name" className="block font-medium mb-1">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                className={`w-full p-2 border ${
                  formErrors.name ? "border-red-500" : "border-slate-300"
                } rounded`}
              />
              {formErrors.name && (
                <p className="text-red-500">{formErrors.name}</p>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="phone" className="block font-medium mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                value={form.phone}
                onChange={handleChange}
                className={`w-full p-2 border ${
                  formErrors.phone ? "border-red-500" : "border-slate-300"
                } rounded`}
              />
              {formErrors.phone && (
                <p className="text-red-500">{formErrors.phone}</p>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="address" className="block font-medium mb-1">
                Address
              </label>
              <textarea
                name="address"
                value={form.address}
                onChange={handleChange}
                className={`w-full p-2 border ${
                  formErrors.address ? "border-red-500" : "border-slate-300"
                } rounded`}
                rows="3"
              ></textarea>
              {formErrors.address && (
                <p className="text-red-500">{formErrors.address}</p>
              )}
            </div>

            <div className="flex gap-4 mb-4">
              <div className="w-1/2">
                <label htmlFor="city" className="block font-medium mb-1">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                  className={`w-full p-2 border ${
                    formErrors.city ? "border-red-500" : "border-slate-300"
                  } rounded`}
                />
                {formErrors.city && (
                  <p className="text-red-500">{formErrors.city}</p>
                )}
              </div>

              <div className="w-1/2">
                <label htmlFor="state" className="block font-medium mb-1">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                  className={`w-full p-2 border ${
                    formErrors.state ? "border-red-500" : "border-slate-300"
                  } rounded`}
                />
                {formErrors.state && (
                  <p className="text-red-500">{formErrors.state}</p>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="postalCode" className="block font-medium mb-1">
                Postal Code
              </label>
              <input
                type="text"
                name="postalCode"
                value={form.postalCode}
                onChange={handleChange}
                className={`w-full p-2 border ${
                  formErrors.postalCode ? "border-red-500" : "border-slate-300"
                } rounded`}
              />
              {formErrors.postalCode && (
                <p className="text-red-500">{formErrors.postalCode}</p>
              )}
            </div>

            <button
              type="submit"
              className="bg-blue-600 text-white py-2 px-4 rounded"
            >
              Place Order
            </button>
          </form>
        </div>

        {/* Order Summary */}
        <div className="w-full max-w-sm bg-white p-4 border border-slate-300 rounded">
          <h2 className="text-white bg-red-600 px-4 py-1">Order Summary</h2>
          <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
            <p>Subtotal</p>
            <p>{`PKR ${subtotal}`}</p>
          </div>

          <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600">
            <p>Delivery Charges</p>
            <p>{`PKR ${deliveryCharge}`}</p>
          </div>

          <div className="flex items-center justify-between px-4 gap-2 font-medium text-lg text-slate-600 mt-2">
            <p>Total Amount</p>
            <p>{`PKR ${totalAmount}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryInformation;
