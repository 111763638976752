import React from "react";

const TermsOfService = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8 px-4">
      {/* Main Content */}
      <div className="w-full max-w-4xl bg-white p-6 rounded shadow-md">
        <h1 className="text-4xl font-bold text-center mb-6">
          Terms of Service
        </h1>

        {/* Introduction Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
          <p className="text-slate-600 leading-relaxed">
            Welcome to ST-Enterprises! These terms and conditions outline the
            rules and regulations for the use of our website and services. By
            accessing or using our website, you agree to comply with these
            terms. If you do not agree with any part of these terms, please do
            not use our services.
          </p>
        </section>

        {/* Accounts Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Accounts</h2>
          <p className="text-slate-600 leading-relaxed">
            When you create an account with us, you must provide accurate and
            complete information. You are responsible for safeguarding your
            account and for any activity under your account. If we suspect
            unauthorized use of your account, we may suspend or terminate your
            access to the site.
          </p>
        </section>

        {/* Usage of the Website Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Usage of the Website</h2>
          <p className="text-slate-600 leading-relaxed">
            By using our website, you agree not to:
          </p>
          <ul className="list-disc list-inside text-slate-600 leading-relaxed mt-4">
            <li>Engage in any unlawful or prohibited activities</li>
            <li>
              Use the website in a way that could damage, disable, or impair
              services
            </li>
            <li>Interfere with others' use of the website</li>
            <li>
              Attempt to gain unauthorized access to any services or accounts
            </li>
          </ul>
        </section>

        {/* Intellectual Property Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
          <p className="text-slate-600 leading-relaxed">
            All content on this website, including text, images, graphics, and
            code, is the property of ST-Enterprises. You are not permitted to
            reproduce, distribute, or use any part of the content without our
            prior written consent.
          </p>
        </section>

        {/* Limitation of Liability Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            Limitation of Liability
          </h2>
          <p className="text-slate-600 leading-relaxed">
            ST-Enterprises will not be held liable for any damages that arise
            from the use or inability to use the website. This includes, but is
            not limited to, direct, indirect, incidental, or consequential
            damages. You agree to use the website at your own risk.
          </p>
        </section>

        {/* Termination Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Termination</h2>
          <p className="text-slate-600 leading-relaxed">
            We reserve the right to terminate or suspend your access to our
            website without prior notice if you violate these terms. Upon
            termination, your right to use our services will cease immediately.
          </p>
        </section>

        {/* Changes to Terms Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
          <p className="text-slate-600 leading-relaxed">
            ST-Enterprises reserves the right to modify or replace these terms
            at any time. It is your responsibility to review these terms
            periodically. Your continued use of the website after changes are
            made constitutes acceptance of those changes.
          </p>
        </section>

        {/* Contact Us Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className="text-slate-600 leading-relaxed">
            If you have any questions or concerns regarding these Terms of
            Service, please feel free to contact us at{" "}
            <span className="font-semibold">stenterprises424@gmail.com</span> or
            call us at <span className="font-semibold">+92 301 6760424</span>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
