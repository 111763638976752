import React, { useContext, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { setUserDetails } from "../store/userSlice";
import Context from "../context";

const SmallScreenHeader = () => {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: "include",
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  return (
    <>
      {/* Crawling Text */}
      <div className="marquee">
        <p>
          🚨 Limited-Time Offers at ST Enterprises! Shop the Latest Gadgets &
          Accessories Now! 🚨 Huge Discounts on Smartwatches, Earphones, and
          More! Don't Miss Out – Shop Today! 🎉{" "}
        </p>
      </div>

      <header
        style={{
          background: "linear-gradient(180deg, #f7f7f7, #d3d3d3)",
          padding: "0px 15px",
          zIndex: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.7)",
          position: "fixed", // Fixed position for header
          top: "20px", // Adjusted to be right below the crawling text
          width: "100%",
          transition: "all 0.3s ease-in-out",
        }}
        className="small-screen-header"
      >
        {/* Logo */}
        <div className="branding">
          <Link to={"/"}>
            <div className="logo">
              <h1 style={styles.logoCombined}>
                ST <span style={styles.logoSmall}>ENTERPRISES</span>
                <span style={styles.trademark}>™</span>
              </h1>
            </div>
          </Link>
        </div>

        {/* Right Align Icons with Login/Logout in Row */}
        <div className="header-icons flex items-center space-x-4">
          {/* Login/Logout Button */}
          <div className="auth-button">
            {user?._id ? (
              <button
                onClick={handleLogout}
                className="px-1.5 py-1 rounded-full text-white bg-red-600 hover:bg-red-700 transition-all duration-300 ease-in-out shadow-md"
              >
                Logout
              </button>
            ) : (
              <Link
                to={"/login"}
                className="px-1.5 py-1 rounded-full text-white bg-black hover:bg-red-500 transition-all duration-300 ease-in-out shadow-md"
              >
                Login
              </Link>
            )}
          </div>

          {/* Cart Icon with Cart Count */}
          <Link to={"/cart"} className="text-2xl relative">
            <FaShoppingCart size={30} className="text-black" />{" "}
            {/* Adjusted cart icon size */}
            {context?.cartProductCount > 0 && (
              <div className="cart-count">
                <p>{context?.cartProductCount}</p>
              </div>
            )}
          </Link>

          {/* User Icon */}
          <div className="relative flex">
            {user?._id ? (
              <div
                className="user-menu"
                onClick={() => setMenuDisplay(!menuDisplay)}
              >
                {user?.profilePic ? (
                  <img
                    src={user?.profilePic}
                    className="w-10 h-10 rounded-full" // Adjusted size for logged-in user image
                    alt={user?.name}
                  />
                ) : (
                  <FaRegCircleUser size={25} /> // Adjusted size for default user icon
                )}
              </div>
            ) : (
              <Link to={"/login"} className="login-icon">
                <FaRegCircleUser size={25} />{" "}
                {/* Adjusted size for login icon */}
              </Link>
            )}
          </div>
        </div>
      </header>

      {/* Separated CSS for Crawling Text and Cart Count */}
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap");

        .marquee {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          background-color: #000;
          color: #ffffff;
          height: 25px; /* Adjusted height */
          line-height: 25px; /* Aligns text vertically within the container */
          text-align: center;
          position: fixed; /* Fixed to move with the page */
          top: 0; /* Stick to the top */
          z-index: 30; /* Ensures crawling text stays on top */
          font-family: "Playfair Display", serif; /* Elegant font */
          font-size: 1.5rem; /* Increased font size */
        }

        .marquee p {
          display: inline-block;
          animation: scroll 20s linear infinite;
        }

        @keyframes scroll {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        .cart-count {
          position: absolute;
          top: -10px;
          right: -10px;
          background-color: red;
          color: white;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: bold;
        }

        @media (max-width: 768px) {
          .header-icons {
            justify-content: space-around;
          }

          .marquee {
            font-size: 1rem; /* Slightly smaller font size for mobile */
            height: 20px; /* Decreased size for smaller screens */
            line-height: 20px;
          }

          img {
            width: 150px;
            height: 150px;
          }

          /* Responsive logo design */
          .logo h1 {
            font-size: 2rem; /* Smaller size for small screens */
          }
          .logo span {
            font-size: 1rem; /* Adjust ENTERPRISES font size */
          }
        }
      `}</style>
    </>
  );
};

const styles = {
  logoCombined: {
    fontFamily: "'Playfair Display', serif",
    fontSize: "1.9rem",
    fontWeight: 800,
    color: "#000",
  },
  logoSmall: {
    fontFamily: "'Merriweather', serif",
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#000",
  },
  trademark: {
    fontSize: "0.6rem",
    verticalAlign: "super",
    fontWeight: 300,
    color: "#000",
  },
};

export default SmallScreenHeader;
