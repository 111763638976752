import React, { useContext, useState } from "react";
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa"; // Social icons import
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SummaryApi from "../common";
import { toast } from "react-toastify";
import { setUserDetails } from "../store/userSlice";
import ROLE from "../common/role";
import Context from "../context";

const Header = () => {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false); // Toggle search bar visibility
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: "include",
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      navigate(`/search?q=${value}`);
    } else {
      navigate("/search");
    }
  };

  return (
    <>
      {/* Crawling Text */}
      <div className="marquee">
        <p>
          🚨 Limited-Time Offers at ST Enterprises! Shop the Latest Gadgets &
          Accessories Now! 🚨 Huge Discounts on Smartwatches, Earphones, and
          More! Don't Miss Out – Shop Today! 🎉{" "}
        </p>
      </div>

      {/* Main Header */}
      <header
        style={{
          background: "linear-gradient(0deg, #ffffff,#000000)", // Gradient from light gray to dark gray
          padding: "15px 20px",
          zIndex: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.7)", // Strong shadow for depth
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)", // Thin border for style
          position: "fixed",
          top: "0px", // Top of the page
          width: "100%",
          transition: "all 0.3s ease-in-out",
        }}
        className="h-20 w-full z-10"
      >
        <div className="header-top container mx-auto flex justify-between items-center w-full">
          {/* Left: Social Media Icons - Hidden on Small Screens */}
          <div
            className="flex items-center gap-5 social-icons"
            style={{
              fontSize: "1.8rem",
              color: "#505050", // Dark grey icons
            }}
          >
            <a
              href="https://www.facebook.com/profile.php?id=61563109363726&mibextid=ZbWKwL"
              className="social-icon facebook"
              style={{ color: "#333333" }} // Initial dark grey color
              onMouseEnter={(e) => (e.target.style.color = "#000000")} // Hover effect to black
              onMouseLeave={(e) => (e.target.style.color = "#505050")} // Reset to dark grey
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/st.enterprises?igsh=MWRvbHFlMmo5OTNnYg%3D%3D&utm_source=qr"
              className="social-icon instagram"
              style={{ color: "#333333" }} // Initial dark grey color
              onMouseEnter={(e) => (e.target.style.color = "#000000")} // Hover effect to black
              onMouseLeave={(e) => (e.target.style.color = "#505050")} // Reset to dark grey
            >
              <FaInstagram />
            </a>
            <a
              href="https://wa.me/923016760424"
              className="social-icon whatsapp"
              style={{ color: "#333333" }} // Initial dark grey color
              onMouseEnter={(e) => (e.target.style.color = "#000000")} // Hover effect to black
              onMouseLeave={(e) => (e.target.style.color = "#505050")} // Reset to dark grey
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://youtube.com/@stenterprises-p7e?si=7GP97Ij5goec_Bpv"
              className="social-icon youtube"
              style={{ color: "#333333" }} // Initial dark grey color
              onMouseEnter={(e) => (e.target.style.color = "#000000")} // Hover effect to black
              onMouseLeave={(e) => (e.target.style.color = "#505050")} // Reset to dark grey
            >
              <FaYoutube />
            </a>
          </div>

          {/* Center: Logo */}
          <div className="branding text-center">
            <Link to={"/"}>
              <div className="logo">
                <h1 style={styles.logoCombined}>
                  ST <span style={styles.logoSmall}>ENTERPRISES</span>
                  <span style={styles.trademark}>™</span>
                </h1>
              </div>
            </Link>
          </div>

          {/* Right: Search Icon and User Icons */}
          <div className="header-icons flex items-center">
            {/* Search Icon */}
            <div
              className="text-3xl cursor-pointer hover:text-red-500 search-icon"
              onClick={() => setSearchVisible(!searchVisible)} // Toggle search bar
              style={{
                color: "#333333", // Dark grey for search icon
              }}
            >
              <GrSearch />
            </div>

            {/* Cart and User Icon */}
            <div className="flex items-center gap-8 ml-4">
              <Link
                to={"/cart"}
                className="text-2xl relative text-grey-700 hover:text-red-500"
                style={{ color: "#333333" }} // Dark grey for cart icon
              >
                <FaShoppingCart />
                {context?.cartProductCount > 0 && (
                  <div className="bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3">
                    <p className="text-sm">{context?.cartProductCount}</p>
                  </div>
                )}
              </Link>

              <div className="relative flex justify-center">
                {user?._id ? (
                  <div
                    className="text-3xl cursor-pointer relative flex justify-center text-grey-700 hover:text-red-500"
                    style={{ color: "#333333" }} // Dark grey for user icon
                    onClick={() => setMenuDisplay((prev) => !prev)}
                  >
                    {user?.profilePic ? (
                      <img
                        src={user?.profilePic}
                        className="w-13 h-12 rounded-full"
                        alt={user?.name}
                        style={{
                          width: window.innerWidth <= 768 ? "40px" : "50px",
                          height: window.innerWidth <= 768 ? "40px" : "50px",
                        }}
                      />
                    ) : (
                      <FaRegCircleUser />
                    )}
                  </div>
                ) : (
                  <Link
                    to={"/login"}
                    className="text-3xl text-grey-700 hover:text-red-500"
                    style={{ color: "#505050" }} // Dark grey for login icon
                  >
                    <FaRegCircleUser />
                  </Link>
                )}

                {menuDisplay && (
                  <div className="absolute bg-white bottom-0 top-14 h-fit p-0 shadow-lg rounded-lg">
                    <nav>
                      {user?.role === ROLE.ADMIN && (
                        <Link
                          to={"/admin-panel/all-products"}
                          className="whitespace-nowrap block hover:bg-gray-100 p-2"
                          onClick={() => setMenuDisplay((prev) => !prev)}
                        >
                          Admin Panel
                        </Link>
                      )}
                    </nav>
                  </div>
                )}
              </div>

              <div>
                {user?._id ? (
                  <button
                    onClick={handleLogout}
                    className="px-4 py-2 rounded-full text-white bg-red-600 hover:bg-red-700 transition-all duration-300 ease-in-out shadow-md"
                  >
                    Logout
                  </button>
                ) : (
                  <Link
                    to={"/login"}
                    className="px-4 py-2 rounded-full text-white bg-black hover:bg-red-500 transition-all duration-300 ease-in-out shadow-md"
                  >
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Conditional Search Bar */}
        {searchVisible && (
          <div className="flex justify-center w-full mt-4">
            <div className="flex items-center w-full max-w-lg rounded-full shadow-md border border-gray-300 bg-white focus-within:shadow-lg transition-all duration-300 ease-in-out">
              {/* Input field */}
              <input
                type="text"
                placeholder="Search for amazing products..."
                className="w-full px-6 py-2.5 bg-transparent text-gray-700 text-lg rounded-full outline-none placeholder-gray-500 transition-all duration-300 focus:placeholder-transparent"
                onChange={handleSearch}
                value={search}
              />
              {/* Search Icon */}
              <div className="min-w-[50px] h-10 bg-red-500 text-white rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-110 hover:translate-x-2">
                <GrSearch />
              </div>
            </div>
          </div>
        )}
      </header>

      {/* Separated CSS for Crawling Text */}
      <style jsx>{`
        .marquee {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          background-color: #000;
          color: #ffffff;
          padding: 0px 0;
          text-align: center;
          position: fixed;
          top: 0;
          z-index: 30; /* Ensures crawling text stays on top */
          font-family: "Playfair Display", serif;
          font-size: 1.2rem;
          letter-spacing: 0.05em;
        }

        .marquee p {
          display: inline-block;
          animation: scroll 20s linear infinite;
        }

        @keyframes scroll {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @media (max-width: 768px) {
          /* Hide social media icons on small screens */
          .social-icons {
            display: none;
          }

          .header-icons {
            justify-content: space-around;
          }

          /* Responsive logo design */
          .logo h1 {
            font-size: 1.5rem; /* Smaller size for small screens */
          }
          .logo span {
            font-size: 0.8rem; /* Adjust ENTERPRISES font size */
          }

          .search-icon {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

// Inline styles for logo
const styles = {
  logoCombined: {
    fontFamily: "'Playfair Display', serif",
    fontSize: "3rem",
    fontWeight: 700,
    color: "#000", // Dark grey text
    transition: "color 0.3s ease",
  },
  logoSmall: {
    fontFamily: "'Merriweather', serif",
    fontSize: "1.3rem",
    fontWeight: 300,
    color: "#000",
    letterSpacing: "0.1em",
  },
  trademark: {
    fontSize: "1rem",
    verticalAlign: "super",
    fontWeight: 300,
    color: "#000",
  },
};

export default Header;
