import React, { useEffect, useState, useRef } from "react";
import SummaryApi from "../common";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Importing chevron icons

const CategoryList = () => {
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  const categoryLoading = new Array(16).fill(null);

  const sliderRef = useRef(null);

  const fetchCategoryProduct = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.categoryProduct.url);
    const dataResponse = await response.json();
    setLoading(false);
    setCategoryProduct(dataResponse.data);
  };

  useEffect(() => {
    fetchCategoryProduct();
  }, []);

  // Function to scroll the slider
  const scrollSlider = (direction) => {
    const slider = sliderRef.current;
    const scrollAmount = direction === "left" ? -200 : 200;
    slider.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="container mx-auto p-4 relative">
      {/* Left Arrow */}
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-white-900 bg-opacity-50 hover:bg-opacity-80 text-grey rounded-full shadow-lg transition-all duration-300 z-10"
        onClick={() => scrollSlider("left")}
        aria-label="Scroll Left"
      >
        <FaChevronLeft className="w-6 h-6" />
      </button>

      {/* Right Arrow */}
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-white-900 bg-opacity-50 hover:bg-opacity-80 text-grey rounded-full shadow-lg transition-all duration-300 z-10"
        onClick={() => scrollSlider("right")}
        aria-label="Scroll Right"
      >
        <FaChevronRight className="w-6 h-6" />
      </button>

      <div
        ref={sliderRef}
        className="flex items-center gap-4 justify-between overflow-x-scroll scrollbar-none scroll-smooth"
      >
        {loading
          ? categoryLoading.map((el, index) => {
              return (
                <div
                  className="h-16 w-16 md:w-20 md:h-20 rounded-full overflow-hidden bg-slate-200 animate-pulse"
                  key={"categoryLoading" + index}
                ></div>
              );
            })
          : categoryProduct.map((product, index) => {
              return (
                <Link
                  to={"/product-category?category=" + product?.category}
                  className="cursor-pointer"
                  key={product?.category}
                >
                  <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden p-4 bg-slate-200 flex items-center justify-center">
                    <img
                      src={product?.productImage[0]}
                      alt={product?.category}
                      className="h-full object-scale-down mix-blend-multiply hover:scale-125 transition-all"
                    />
                  </div>
                  <p className="text-center text-sm md:text-base capitalize">
                    {product?.category}
                  </p>
                </Link>
              );
            })}
      </div>
    </div>
  );
};

export default CategoryList;
