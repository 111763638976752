import React, { useEffect, useState } from "react";
import image1 from "../assest/banner/img1.webp";
import image2 from "../assest/banner/img2.webp";
import image3 from "../assest/banner/img3.jpg";
import image4 from "../assest/banner/img4.jpg";

import image1Mobile from "../assest/banner/img1_mobile.jpg";
import image2Mobile from "../assest/banner/img2_mobile.webp";
import image3Mobile from "../assest/banner/img3_mobile.jpg";
import image4Mobile from "../assest/banner/img4_mobile.jpg";

import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

const BannerProduct = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const desktopImages = [image1, image2, image3, image4];
  const mobileImages = [image1Mobile, image2Mobile, image3Mobile, image4Mobile];

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % desktopImages.length);
  };

  const prevImage = () => {
    setCurrentImage(
      (prev) => (prev - 1 + desktopImages.length) % desktopImages.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto px-4">
      <div className="relative w-full h-[60vh] md:h-[80vh] bg-gray-100 overflow-hidden rounded-lg shadow-lg">
        {/* Desktop and Tablet navigation */}
        <div className="absolute inset-0 z-10 flex items-center justify-between px-4 hidden md:flex">
          <button
            onClick={prevImage}
            className="bg-white/80 p-3 rounded-full shadow-lg hover:bg-white transition duration-300"
          >
            <FaAngleLeft className="text-3xl text-gray-700" />
          </button>
          <button
            onClick={nextImage}
            className="bg-white/80 p-3 rounded-full shadow-lg hover:bg-white transition duration-300"
          >
            <FaAngleRight className="text-3xl text-gray-700" />
          </button>
        </div>

        {/* Image display for Desktop */}
        <div className="hidden md:flex h-full w-full overflow-hidden">
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentImage * 100}%)` }}
          >
            {desktopImages.map((imageURl, index) => (
              <div className="w-full h-full flex-shrink-0" key={index}>
                <img
                  src={imageURl}
                  className="w-full h-full object-cover rounded-lg"
                  alt={`banner-${index}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Image display for Mobile */}
        <div className="md:hidden h-full w-full overflow-hidden">
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{ transform: `translateX(-${currentImage * 100}%)` }}
          >
            {mobileImages.map((imageURl, index) => (
              <div className="w-full h-full flex-shrink-0" key={index}>
                <img
                  src={imageURl}
                  className="w-full h-full object-cover rounded-lg"
                  alt={`banner-${index}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Indicators */}
        <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {desktopImages.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 rounded-full transition-all duration-300 ${
                currentImage === index ? "bg-gray-800" : "bg-gray-400"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BannerProduct;
