import React from "react";
import coFounderImage from "../assest/banner/cofounder.jpg"; // Image path for the co-founder
import devCoFounderImage from "../assest/banner/developer.jpg"; // Image path for the developer-co-founder
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa"; // Import icons

const Team = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 py-8 px-4">
      <div className="w-full max-w-5xl bg-white p-8 rounded-lg shadow-lg">
        {/* Elegant Font for Heading */}
        <h1
          className="text-4xl font-bold text-center text-gray-800 mb-12"
          style={{ fontFamily: "Lora, serif" }}
        >
          Meet Our Team
        </h1>

        {/* Co-Founder Section */}
        <section className="flex flex-col md:flex-row items-center justify-between mb-12">
          <img
            src={coFounderImage}
            alt="Co-Founder"
            className="w-40 h-40 md:w-48 md:h-48 rounded-full object-cover shadow-lg mb-6 md:mb-0"
          />
          <div className="text-center md:text-left md:ml-8">
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">
              TANVEER SHAHZAD
            </h2>
            <p className="text-xl text-gray-500 mb-4">Co-Founder</p>
            <p className="text-gray-600 leading-relaxed">
              Tanveer is the hardworking co-founder of ST-Enterprises. With a
              deep passion for business and a strong sense of responsibility, he
              is always doing his best. His dedication has helped shape the
              company, and he continues to play an important role in its
              success.
            </p>
          </div>
        </section>

        {/* Developer-Co-Founder Section */}
        <section className="flex flex-col md:flex-row items-center justify-between">
          <img
            src={devCoFounderImage}
            alt="Developer-Co-Founder"
            className="w-40 h-40 md:w-48 md:h-48 rounded-full object-cover shadow-lg mb-6 md:mb-0"
          />
          <div className="text-center md:text-left md:ml-8">
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">
              AHMAD HASSAN
            </h2>
            <p className="text-xl text-gray-500 mb-4">Developer</p>
            <p className="text-gray-600 leading-relaxed">
              Ahmad is the technical brain behind ST-Enterprises. He is
              currently pursuing his Computer Science degree at the University
              of Management and Technology, Lahore. Self-taught, he developed
              this website and is now an integral part of our team. His goal is
              to continue enhancing his skills and work on projects that make a
              meaningful impact.
            </p>

            {/* Contact Links */}
            <div className="mt-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Connect with Ahmad
              </h3>
              <div className="flex justify-center md:justify-start gap-6">
                {/* LinkedIn */}
                <a
                  href="https://www.linkedin.com/in/ahmad-hassan3110"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 transition duration-200"
                >
                  <FaLinkedin className="text-4xl" />
                </a>
                {/* GitHub */}
                <a
                  href="https://github.com/Ahmadhassan30"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-800 hover:text-black transition duration-200"
                >
                  <FaGithub className="text-4xl" />
                </a>
                {/* Email */}
                <a
                  href="mailto:ahmadhassan30nov@gmail.com"
                  className="text-red-600 hover:text-red-800 transition duration-200"
                >
                  <FaEnvelope className="text-4xl" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Team;
