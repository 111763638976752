const productCategory = [
  { id: 1, label: "Airpodes", value: "Airpodes" },
  { id: 2, label: "Monitors", value: "Monitors" },
  { id: 3, label: "Headphones", value: "Headphones" },
  { id: 4, label: "Keyboard", value: "Keyboard" },
  { id: 5, label: "Mouse", value: "Mouse" },
  { id: 6, label: "Printers", value: "Printers" },
  { id: 7, label: "Accessories", value: "Accessories" },
  { id: 8, label: "Macbook", value: "Macbook" },
  { id: 9, label: "Speakers", value: "Speakers" },
  { id: 10, label: "Bags", value: "Bags" },
  { id: 11, label: "PowerBank", value: "PowerBank" },
  { id: 12, label: "Smart Watches", value: " Smart Watches" },
  { id: 13, label: "Window Machines", value: "Window Machines" },
  { id: 14, label: "Stationary", value: "Stationary" },
  { id: 15, label: "Storage Devices", value: "Storage Deices" },
  { id: 16, label: "Electronics", value: "Electronics" },
  { id: 17, label: "Home Decor", value: "Home Decor" },
];

export default productCategory;
