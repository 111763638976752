import logo from "./logo.svg";
import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./components/Header"; // Header for large screens
import SmallScreenHeader from "./components/SmallScreenHeader"; // Header for small screens
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUS"; // Import the AboutUs component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import SummaryApi from "./common";
import Context from "./context";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./store/userSlice";
import CategroyWiseProductDisplay from "./components/CategoryWiseProductDisplay.js"; // Large screen product display

function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // New state to handle screen size

  const fetchUserDetails = async () => {
    const dataResponse = await fetch(SummaryApi.current_user.url, {
      method: SummaryApi.current_user.method,
      credentials: "include",
    });

    const dataApi = await dataResponse.json();

    if (dataApi.success) {
      dispatch(setUserDetails(dataApi.data));
    }
  };

  const fetchUserAddToCart = async () => {
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url, {
      method: SummaryApi.addToCartProductCount.method,
      credentials: "include",
    });

    const dataApi = await dataResponse.json();

    setCartProductCount(dataApi?.data?.count);
  };

  useEffect(() => {
    /** Fetch user details */
    fetchUserDetails();
    /** Fetch user cart product count */
    fetchUserAddToCart();

    // Add listener for window resize to toggle between small and large headers
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Initialize the screen size on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the listener on unmount
    };
  }, []);

  return (
    <>
      <Context.Provider
        value={{
          fetchUserDetails, // user detail fetch
          cartProductCount, // current user add to cart product count,
          fetchUserAddToCart,
        }}
      >
        <ToastContainer position="top-center" />

        {/* Conditionally render the header based on screen size */}
        {isSmallScreen ? <SmallScreenHeader /> : <Header />}

        <main className="min-h-[calc(100vh-120px)] pt-16">
          <Outlet />
        </main>
        <Footer />
      </Context.Provider>
    </>
  );
}

export default App;
