import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassowrd"; // Fixed the typo
import SignUp from "../pages/SignUp";
import AdminPanel from "../pages/AdminPanel";
import AllUsers from "../pages/AllUsers";
import AllProducts from "../pages/AllProducts";
import CategoryProduct from "../pages/CategoryProduct";
import ProductDetails from "../pages/ProductDetails";
import Cart from "../pages/Cart";
import SearchProduct from "../pages/SearchProduct";
import AboutUs from "../pages/AboutUS"; // Fixed capitalization
import ContactUs from "../pages/ContactUs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ShippingAndReturn from "../pages/ShippingAndReturn";
import TermsOfService from "../pages/TermsOfService";
import Team from "../pages/Team";
import DeliveryInformation from "../pages/DeliveryInformation";
import AllOrders from "../pages/AllOrders"; // Path is correct
import AllReviews from "../pages/AllReviews"; // Ensure this path is correct
import ReviewPage from "../pages/ReviewPage"; // Ensure the path is correct

// Set up your routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // Main app component
    children: [
      {
        path: "", // Home route, when no path is provided
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password", // Fixed the typo here from "ForgotPassowrd" to "ForgotPassword"
        element: <ForgotPassword />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "product-category",
        element: <CategoryProduct />,
      },
      {
        path: "product/:id",
        element: <ProductDetails />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "search",
        element: <SearchProduct />,
      },
      {
        path: "about-us", // Corrected capitalization and path
        element: <AboutUs />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "shipping-return",
        element: <ShippingAndReturn />,
      },
      {
        path: "terms-of-service", // Corrected the path for terms of service
        element: <TermsOfService />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "delivery", // Updated path for delivery info
        element: <DeliveryInformation />,
      },
      {
        path: "reviews/:productId", // Route for displaying all reviews of a product
        element: <AllReviews />, // Ensure this component handles displaying the list of reviews
      },
      {
        path: "review-page/:productId", // New route for the review page
        element: <ReviewPage />, // Route for the review page where users can submit new reviews
      },
      {
        path: "admin-panel",
        element: <AdminPanel />,
        children: [
          {
            path: "all-users",
            element: <AllUsers />,
          },
          {
            path: "all-products",
            element: <AllProducts />,
          },
          {
            path: "all-orders", // Route for orders management in admin panel
            element: <AllOrders />,
          },
        ],
      },
    ],
  },
]);

export default router;
