import React, { useState, useEffect } from "react";
import SummaryApi from "../common/index";
import { FaStar } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";

const ProductReview = ({ productId, productName, brandName, onClose }) => {
  const [reviews, setReviews] = useState([]);
  const [form, setForm] = useState({
    name: "",
    rating: 1,
    comment: "",
    productName: productName,
    brandName: brandName,
  });
  const [formErrors, setFormErrors] = useState({});
  const [viewReviewsModal, setViewReviewsModal] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `https://api.st-enterprises.store/api/reviews/product/${productId}`,
          {
            method: "GET",
          }
        );

        // Check for non-2xx response
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          setReviews(data.reviews); // Fetch reviews for that productId
        } else {
          throw new Error(data.message || "Failed to fetch reviews.");
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        toast.error("An error occurred while fetching reviews.");
      }
    };

    fetchReviews();
  }, [productId]); // Fetch reviews whenever productId changes

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleRatingChange = (rating) => {
    setForm({ ...form, rating });
  };

  const validateForm = () => {
    const errors = {};
    if (!form.name) errors.name = "Name is required";
    if (!form.comment) errors.comment = "Comment is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await fetch(SummaryApi.createReview.url, {
          method: SummaryApi.createReview.method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            productId,
            rating: form.rating,
            comment: form.comment,
            customerName: form.name,
            productName: form.productName,
            brandName: form.brandName,
          }),
        });

        const data = await response.json();

        if (data.success) {
          setReviews((prev) => [...prev, data.review]); // Add the new review to the list
          setForm({
            name: "",
            rating: 1,
            comment: "",
            productName: productName,
            brandName: brandName,
          });
          toast.success(data.message || "Review added successfully!");
          onClose();
        } else {
          toast.error(data.message || "Failed to add review.");
        }
      } catch (error) {
        console.error("Error submitting review:", error);
        toast.error("An error occurred while submitting the review.");
      }
    }
  };

  return (
    <div className="fixed w-full h-full bg-slate-200 bg-opacity-75 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-md h-auto overflow-hidden">
        {" "}
        {/* Changed max-w-2xl to max-w-md and reduced padding */}
        <div className="flex justify-between items-center pb-4 border-b border-gray-300 mb-4">
          <h2
            style={{
              color: "#FFFAFA",
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
              fontSize: "1.25rem", // Changed font size for smaller appearance
              backgroundColor: "#2563EB",
              padding: "0px",
              marginTop: "10px",
            }}
          >
            Share your valuable thoughts on {productName}
          </h2>
          <div
            className="text-1xl hover:text-red-600 cursor-pointer"
            onClick={onClose}
          >
            <CgClose />
          </div>
        </div>
        <form className="grid p-4 gap-4" onSubmit={handleSubmit}>
          <div className="border rounded-lg p-4 shadow-md">
            <label className="font-semibold">Name</label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              className="p-2 bg-slate-100 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 w-full"
              placeholder="Enter your name"
              required
            />
            {formErrors.name && (
              <p className="text-red-600 text-xs">{formErrors.name}</p>
            )}
          </div>

          <div className="border rounded-lg p-4 shadow-md">
            <label className="font-semibold">Rating</label>
            <div className="flex gap-2 mb-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => handleRatingChange(star)}
                  style={{
                    cursor: "pointer",
                    color: form.rating >= star ? "#FFD700" : "#ccc",
                    fontSize: "1.5rem",
                  }}
                >
                  <FaStar />
                </span>
              ))}
            </div>
          </div>

          <div className="border rounded-lg p-4 shadow-md">
            <label className="font-semibold">Comment</label>
            <textarea
              name="comment"
              value={form.comment}
              onChange={handleChange}
              className="h-28 bg-slate-100 border border-gray-300 resize-none p-2 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 w-full"
              placeholder="Enter your review here"
              required
            />
            {formErrors.comment && (
              <p className="text-red-600 text-xs">{formErrors.comment}</p>
            )}
          </div>

          <div className="flex gap-2">
            <button className="flex-1 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200">
              Submit Review
            </button>
            <button
              type="button"
              onClick={() => setViewReviewsModal(true)}
              className="flex-1 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
            >
              View Reviews
            </button>
          </div>
        </form>
        {/* View Reviews Modal */}
        {viewReviewsModal && (
          <div className="fixed w-full h-full bg-slate-200 bg-opacity-75 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl h-auto overflow-hidden">
              <div className="flex justify-between items-center pb-4 border-b border-gray-300 mb-4">
                <h3
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.5rem", // Equivalent to text-lg in Tailwind CSS
                    backgroundColor: "#2563EB",
                    padding: "0px", // To add some spacing around the text
                  }}
                >
                  Reviews for {productName}
                </h3>
                <CgClose
                  className="cursor-pointer text-2xl hover:text-red-600"
                  onClick={() => setViewReviewsModal(false)}
                />
              </div>
              <div className="mt-4 overflow-auto max-h-72">
                {reviews.length > 0 ? (
                  reviews
                    .filter((review) => review.productId === productId) // Ensure only reviews of this product are shown
                    .map((review, index) => (
                      <div
                        key={index}
                        className="border-b border-gray-200 py-2"
                      >
                        <h4 className="font-semibold">{review.customerName}</h4>
                        <div className="flex text-yellow-500">
                          {[...Array(review.rating)].map((_, i) => (
                            <FaStar key={i} />
                          ))}
                        </div>
                        <p className="text-gray-700">{review.comment}</p>
                      </div>
                    ))
                ) : (
                  <p className="text-gray-700">No reviews yet. </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductReview;

