import React, { useEffect, useState } from "react";
import SummaryApi from "../common"; // Assuming you have an API file
import { toast } from "react-toastify"; // For notifications
import moment from "moment"; // For date formatting
import "./AllOrders.css"; // Import custom CSS file

const AllOrders = () => {
  const [allOrders, setAllOrders] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true); // To show loading spinner

  // Fetch all orders from the backend
  const fetchAllOrders = async () => {
    try {
      const response = await fetch(SummaryApi.getAllOrders.url, {
        method: SummaryApi.getAllOrders.method,
        credentials: "include", // If authentication is needed
      });

      const dataResponse = await response.json();
      console.log("API Response:", dataResponse); // Debugging

      // Check if response is successful and contains the orders array
      if (dataResponse.success && Array.isArray(dataResponse.orders)) {
        setAllOrders(dataResponse.orders); // Store the orders in state
      } else {
        toast.error(dataResponse.message || "Failed to fetch orders");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Error fetching orders");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  // Update status both locally and via the API
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      // Make an API call to update the status in the backend
      const response = await fetch(SummaryApi.updateOrderStatus(orderId).url, {
        method: SummaryApi.updateOrderStatus(orderId).method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: newStatus }), // Sending new status to the backend
        credentials: "include", // If authentication is needed
      });

      const result = await response.json();

      if (result.success) {
        // Update the status locally only if the backend update is successful
        const updatedOrders = allOrders.map((order) => {
          if (order._id === orderId) {
            return { ...order, status: newStatus }; // Update the status
          }
          return order;
        });
        setAllOrders(updatedOrders); // Update the state with new status
        toast.success(`Order status updated to ${newStatus}`); // Show success toast
      } else {
        toast.error(result.message || "Failed to update status");
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Error updating order status");
    }
  };

  // Fetch orders on component mount
  useEffect(() => {
    fetchAllOrders(); // Call function to fetch orders
  }, []);

  return (
    <div className="bg-white pb-4">
      <h2 className="font-bold text-lg mb-4">All Orders</h2>
      {/* Show loading text while fetching orders */}
      {loading ? (
        <p>Loading orders...</p>
      ) : Array.isArray(allOrders) && allOrders.length > 0 ? (
        <table className="w-full orderTable">
          <thead>
            <tr className="bg-black text-white">
              <th>Sr.</th>
              <th>Order ID</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Order Date</th>
              <th>Products</th>
            </tr>
          </thead>
          <tbody>
            {allOrders.map((order, index) => (
              <tr key={order._id} className="table-row">
                <td>{index + 1}</td>
                <td>{order._id}</td>
                <td>{order.customerName}</td>
                <td>{order.customerPhone || "No Phone"}</td>
                <td>
                  {order.customerAddress ? (
                    <>
                      {order.customerAddress.addressLine},{" "}
                      {order.customerAddress.city},{" "}
                      {order.customerAddress.state} -{" "}
                      {order.customerAddress.postalCode}
                    </>
                  ) : (
                    "No Address"
                  )}
                </td>
                <td>{`PKR ${order.totalAmount}`}</td>
                <td className="status-column">
                  <select
                    value={order.status}
                    onChange={(e) =>
                      handleStatusChange(order._id, e.target.value)
                    }
                    className={`status-dropdown ${
                      order.status === "Pending"
                        ? "status-pending"
                        : order.status === "Completed"
                        ? "status-completed"
                        : ""
                    }`}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                  </select>
                </td>
                <td>{moment(order.createdAt).format("LL")}</td>
                <td>
                  {order.products.length > 0 ? (
                    <ul>
                      {order.products.map((product) => (
                        <li key={product._id}>
                          {product.productName} - {product.quantity} @ PKR{" "}
                          {product.price}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No products</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default AllOrders;
