// src/context/CartContext.js
import React, { createContext, useState, useEffect } from "react";

// Create the CartContext
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Example of fetching cart data from local storage or an API
  useEffect(() => {
    const fetchCart = async () => {
      // Here you can fetch data from your backend or local storage
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
      setCart(storedCart);
    };

    fetchCart();
  }, []);

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
